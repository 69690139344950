html {
    font-size: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif;
    font-size: 1rem;
    line-height: 1.5;
}

button:disabled {
    opacity: 0.5;
}

#root {
    height: 100%
}

/* To allow long task names in x axis */
svg.recharts-surface {
    overflow: visible;
}

.clickable {
    cursor: pointer;
}

.cf--assignment--highlight {
    color: red;
}

